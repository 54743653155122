import { environment } from './../../environments/environment';

export const DEFAULT_LANG = "en";
 export const APP_NAME = "ERP"
 export const MRP_APP_NAME = "MRP"
export const API_URL = {
    // INVENTORY_LIST: "inventories/stockTransferList"
    INVENTORY_LIST: environment.apiUrl + "/v1/stock/stockTransfers/search",
    LOGIN_URL:'https://api-dev.deskera.xyz/v1/iam/auth/sign-in/web',
    REDIRECT_URL : environment.apiUrl + "/v1/stock/redirecturl",
    WAREHOUSE_LIST: environment.apiUrl + "/v1/stock/warehouse-products",
    WAREHOUSE_LIST_FOR_DROPDOWN: environment.apiUrl + "/v1/stock/warehouses/shortinfo",
    PRODUCT_LIST: environment.apiUrl + "/v1/stock/products/brief-search",
    FETCH_WAREHOUSE_INVENTORY: environment.appsUrl + "/v1/products/inventories/warehouses/warehouse-products",
    ALL_PRODUCT_LIST: environment.apiUrl + "/v1/stock/products/allproductbrief",
    SAVE_STOCK_TRANSFER: environment.apiUrl + "/v1/stock/stockTransfers",
    GET_STOCK_ADJUSTMENT : environment.apiUrl+"/v1/stock/stockAdjustments/search",
    SAVE_STOCK_ADJUSTMENT: environment.apiUrl+"/v1/stock/stockAdjustments",
    DELETE_STOCK_ADJUSTMENT : environment.apiUrl+"/v1/stock/stockAdjustment",
    EXPORT: environment.appsUrl+"/v1/products/inventories/stockExport",
    GET_VALUATION:environment.apiUrl+"/v1/stock/valuation",
    TENANT:{
        GET: (id) => environment.appsUrl+`/v1/tenants/${id}`
    },
    GET_USER_PERMISSIONS: environment.appsUrl+`/v1/tenants/permissions/users`,
    GET_USER_ROLES: environment.appsUrl+`/v1/users/roles`,
    GET_PERMISSIONS_METADATA: environment.appsUrl+ `/v1/tenants/permissions/metadata`,
    ADVANCED_TRACKING: {
        FETCH_DATA: `${environment.appsUrl}/v1/products/inventories/advanced-tracking/search`,
        ADVANCED_TRACKING_DATA: `${environment.appsUrl}/v1/products/inventories/warehouses/warehouse-products-code-advanced-track`,
        COUNT: `${environment.appsUrl}/v1/products/inventories/advanced-tracking/serial-batch-count`,
        DETAILS: `${environment.appsUrl}/v1/products/inventories/advanced-tracking/fulfillment/search`,
        GS_DETAILS: `${environment.appsUrl}/v1/products/inventories/advanced-tracking/goods-received/search`,
        WAREHOUSE_PRODUCTS_ADVANCE_TRACK: `${environment.appsUrl}/v1/products/inventories/warehouses/warehouse-products-code-advanced-track`
    },
    PRODUCT: {
        BASE: `${environment.appsUrl}/v1/products`,
        UOM: `${environment.appsUrl}/v1/products/uom/search`,
        BY_SEQ_CODE: `${environment.appsUrl}/v1/products/doc-seq-codes`
    },
    PRICE_LIST: `${environment.appsUrl}/v1/products/price-list/prices`,
    GET_CUSTOM_FIELD: `${environment.apiUrl}/v1/stock/dimension`,
    GET_CUSTOM_FIELD_BY_MODULES: `${environment.appsUrl}/v1/dimension/custom-fields-by-modules`,
    CUSTOM_FIELD_DROPDOWN_VALUES: `/v1/dimension/attributes/values`,
    GET_DIMENSIONS_BY_CODE: `${environment.appsUrl}/v1/dimension/codes`,
    ADJUSTMENT_REASON: (type: any) =>
        `${environment.appsUrl}/v1/products/inventories/stockAdjustment/reason/search?type=${type}`
}

export const Constants = {
    DATE_FORMAT_DD_MM_YYYY: "DD-MM-YYYY",
    MANUFACTURED_DATE: "manufacturingDate",
    PRODUCT_VARIANT_CODE: "productVariantCode",
    PRODUCT_CODE: "productCode",
    SEARCH: "search",
    QUERY: "query",
    IS_COMBINE: "isCombined",
    FALSE: "false",
    PAGE_LIMIT: "limit",
    TRUE: "true",
    ACTIVE: "active",
    PAGINATION_OPTIONS: [10, 25, 50],
    FETCH_LIMIT: {
        BANK: "100",
        TRACKING: "100"
    },
    EQUALS: "=",
    CUSTOM_FIELD_PAGE_LIMIT: "1000",
    START: "start",
    START_DEFAULT: "0",
    STATUS: "status",
    APP_NAME: "ERP",
   APPLICATION: "application",
   MODULES: "modules"
}

export enum PRODUCT_TYPE {
    TRACKED = "TRACKED",
    NON_TRACKED = "NONTRACKED",
    BILL_OF_MATERIALS = "BILL_OF_MATERIALS"
}

export enum PRODUCT_OFFERING_TYPE {
    GOODS = "GOODS",
    SERVICES = "SERVICES"
}

export enum EXCISE_TYPES {
    FLAT = "FLAT",
    PERCENT = "PERCENT"
}

export const enum SERIAL_TYPE {
    GS = "Goods and Received",
    FULFILLMENT = "Fulfillment"
}

export enum DOCUMENT_MODE {
    NEW = "new",
    VIEW = "view",
    EDIT = "edit",
    LIST = "list",
    COPY = "copy",
    DIRECT = "direct"
}

export enum TRACKING_TYPE {
    BATCH = "BATCH",
    SERIAL = "SERIAL",
    NONE = "NONE"
}
export const PERMISSIONS_BY_MODULE: any = {
    STOCK_ADJUSTMENT: {
      VIEW: 'erp_stock_adjustment_view',
      CREATE: 'erp_stock_adjustment_create',
      EDIT: 'erp_stock_adjustment_edit',
      PRINT: 'erp_stock_adjustment_print',
      DELETE: 'erp_stock_adjustment_delete',
      IMPORT: 'erp_stock_adjustment_import',
      EXPORT: 'erp_stock_adjustment_export'
    },
    STOCK_TRANSFER: {
      VIEW: 'erp_stock_transfer_view',
      CREATE: 'erp_stock_transfer_create',
      EDIT: 'erp_stock_transfer_edit',
      PRINT: 'erp_stock_transfer_print',
      DELETE: 'erp_stock_transfer_delete',
      IMPORT: 'erp_stock_transfer_import',
      EXPORT: 'erp_stock_transfer_export'
    },
    DEFAULT: {
      //only for UI (temporary purpose)
      IS_DEFAULT: 'erp_is_default'
    }
  };

