import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { API_URL } from '../../../common/constant';
import { Constants } from '../dashboard.config';
import { StockAdjustmentServiceService } from '../../stock-adjustment/services/stock-adjustment-service.service';


@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    userLoggedInSubject = new Subject<any>();
    userLoggedInObservable: Observable<any>;

    constructor(
      private http: HttpClient,
      private stockAdjustmentService: StockAdjustmentServiceService
    ) {
    }

    getStockTransfer(params?: HttpParams) {
        return this.http.get(API_URL.INVENTORY_LIST, {
            params: params
          })
    }

    fetchWarehouses() {
        return this.http.get(API_URL.WAREHOUSE_LIST);
    }
    fetchProducts(mode, searchKey = "") {
        if(mode == 'New'){
            return this.http.get(API_URL.PRODUCT_LIST + "?search=" + searchKey, {});
        }
        else{
            return this.http.get(API_URL.ALL_PRODUCT_LIST, {});
        }
    }

     fetchWarehouseProductsByID(data) {
        return this.http
          .post(API_URL.FETCH_WAREHOUSE_INVENTORY, data);
      }
    saveStockTransfer(data) {
        return this.http.post(API_URL.SAVE_STOCK_TRANSFER, data);
    }
    editStockTransfer(data) {
        return this.http.put(API_URL.SAVE_STOCK_TRANSFER, data);
    }
    export(module: string) {
        let params = new HttpParams();
        if (module === "STOCK_ADJUSTMENT") {
          params = params
            .set(Constants.MODULE_NAME, module)
            .set(Constants.PAGE_LIMIT, this.stockAdjustmentService.totalElements)
            .set(Constants.PAGE_NUMBER, "0");
        } else {
          params = params.set(Constants.MODULE_NAME, module);
        }        return this.http.get(API_URL.EXPORT, {
          params,
          observe: "response"
        });
      }

       getPermissions(payload: any) {
        return this.http.post(API_URL.GET_USER_PERMISSIONS, payload)
      }
       getUserRoles() {
        return this.http.get(API_URL.GET_USER_ROLES)
      }
       getPermissionsMetadata() {
        let apps = ['ERP']
        return this.http.post(API_URL.GET_PERMISSIONS_METADATA,apps)
      }
}
