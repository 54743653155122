
export const Constants = {
  SORT_ASC: "ASC",
  SORT_DESC: "DESC",
  QUERY: "query",
  PAGE_LIMIT: "limit",
  PAGE_NUMBER: "page",
  SEARCH: "search",
  SORT: "sort",
  SORT_DIRECTION: "sortDir",
  TRANSFER_DOC_CODE: "code",
  SOURCE_WAREHOUSE_CODE: "srcWarehouseCode",
  DEST_WAREHOUSE_CODE: "destWarehouseCode",
  NOTES: "notes",
  TRANSFER_DOC_NUMBER: "transferDocNumber",
  DATE: "date",
  SOURCEWAREHOUSE: "sourceWarehouse",
  DESTINATIONWAREHOUSE: "destinationWarehouse",
  DEFAULT_DOC_NO: "T-0000",
  PRODUCTS: "products",
  PRODUCT_QUANTITY: "productQuantity",
  AVAILABLE_QUANTITY: "availableQuantity",
  PRODUCT_NAME: "productName",
  PRODUCT_VARIANT_CODE: "productVariantCode",
  QUANTITY: "quantity",
  STOCK_TRANSFER_SAVE_SUCCESS_MSG: "Stock transfer saved successfully",
  STOCK_TRANSFER_MISSING_DETAILS_MSG: "Please update stock transfer details.",
  QUERY_SEPERATOR_CHAR: "~",
  DATE_FORMAT_DD_MM_YYYY: "DD-MM-YYYY",
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
  MODULE_NAME: "moduleName",
  DATE_FORMAT_YYYY_MM_DD: "YYYY-MM-DD",
  TRUE: "true",
  ACTIVE: "active",
  FETCH_LIMIT: "100",
  SAVE: "SAVE",
  CUSTOM_FIELD: "customfield"
};

export const paginationConfig: any = {
    pageSize: "10",
    pageIndex: "0"
};

export const displayedColumns: any[] = [
    'position',
    'productName',
    'availableQuantity',
    'buildAction',
    'action'
];
export const DateLocaleConfig: any = {
    format: "DD-MM-YYYY",
    displayFormat: "D MMM YYYY",
    separator: " - ",
    cancelLabel: "Cancel",
    applyLabel: "Apply",
    firstDay: 1
  };

export const gridColumns: any[] = ['code', 'transferDate', 'notes', 'menu'];

export enum TRACKING_TYPE {
    BATCH = "BATCH",
    SERIAL = "SERIAL",
    NONE = "NONE"
  }
