
import { isNil, isObject } from "lodash";
import * as moment from "moment";
import { APP_NAME, Constants, MRP_APP_NAME, PERMISSIONS_BY_MODULE } from "./constant";
export class Utility {
    static isPresent(value: any): boolean {
        return !this.isBlank(value);
    }
    static isBlank(value: any): boolean {
        return (
            isNil(value) ||
            (isObject(value) && Object.keys(value).length === 0) ||
            value.toString().trim() === ""
        );
    }

    static parseDate(dateStr: string, daysOffset: number = 0): Date {
        const momentDt = dateStr
            ? moment(dateStr, Constants.DATE_FORMAT_DD_MM_YYYY)
            : moment();
        if (daysOffset > 0) {
            momentDt.add(daysOffset, "days");
        }
        return momentDt.toDate();
    }
    static nonEmptyArray(array: any[]) {
    if (array && Array.isArray(array) && array.length > 0) {
      return true;
    }
    return false;
  }

  static getUomQuantity(baseQuantity: any, documentUOMSchemaDefinition: any) {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundingOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        2
      );
    }
    return baseQuantity;
  }

  static roundingOff(
    val: number,
    precisionVal = 2
  ) {
    val = Number(val);
    val = val + 1 / Math.pow(10, precisionVal + 10);
    var newnumber =
      Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
    return newnumber;
  }

  static isEmpty = (value: any) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (Array.isArray(value) || typeof value === 'string') return !value.length;
    return Object.keys(value).length === 0;
  };

  static checkUserPermission = (module: string,permissionsData:any,userRoles:any): boolean => {
    const allAvailablePermissions = permissionsData?.[0]?.apps.filter((app)=> app.appName === APP_NAME  || app.appName === MRP_APP_NAME );
    let permissions = [];
    allAvailablePermissions?.forEach(element => {
      permissions.push(...element.modules)
    });
    // Utility.parseAllAvailAbleModulePermissions(permissionsData,userRoles);
    const appName =
    permissions?.find((perm: any) => perm?.code?.includes(module))
        ?.appName ?? APP_NAME;

    return Utility.checkModulePermissionByModuleName(appName, module,allAvailablePermissions,userRoles);
  };

  static checkModulePermissionByModuleName = (
    appName: string,
    module: string,
    permissionsData:any,
    userRoles:any
  ): boolean => {

    //temporary solution for checking permission for modules like settings
    if (module === PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT) {
      return true;
    }
    const allPermissions = permissionsData;
    let userPermissions = userRoles?.permissions?.[appName];

    if (userPermissions?.includes(module)) {
      return true;
    } else {
      return false;
    }
  };

}