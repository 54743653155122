import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { saveAs } from "file-saver/src/FileSaver";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from "@angular/common";
import { Constants } from './header.config';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { DashboardService } from '../../../pages/dashboard/services/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utility } from '../../../common/utils';
import { PERMISSIONS_BY_MODULE } from '../../../common/constant';
import { LocalStorageConstants } from '../../../authentication/authentication.constants';

@AutoUnsubscribe()
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  selectedApp: string = "";
  user: any;
  permissionsData:any;
  userRoles:any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private router: Router,
              private location: Location,
              private dashboardService: DashboardService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private _snackBar: MatSnackBar) {
                this.router.events.subscribe(event => {
                  if (event instanceof NavigationEnd) {
                    const currentRoute = location.path();
                    if (currentRoute.includes(Constants.STOCK_TRANSFER)) {
                      this.selectedApp = Constants.STOCK_TRANSFER;
                    } else if (currentRoute.includes(Constants.STOCK_ADJUSTMENT)) {
                      this.selectedApp = Constants.STOCK_ADJUSTMENT;
                    } else {
                      this.selectedApp = "";
                    }
                  }
                });
                this.dashboardService.getPermissions([localStorage.getItem(LocalStorageConstants.USER_ID)]).subscribe(
                  (data: any) => {
                    this.permissionsData = data;
                    console.log(this.permissionsData)
                  }
                  );
                this.dashboardService.getUserRoles().subscribe(
                  (data: any) => {
                    this.userRoles = data;
                  }
                  );
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onNewStockTransfer() {
    const event = new CustomEvent("new-stock-add", {});
    window.dispatchEvent(event);
  }
  createStockAdjustment(){
    const event = new CustomEvent("new-stock-adjust", {});
    window.dispatchEvent(event);
  }
  exportData() {
    let moduleName = Constants.STOCK_TRANSFER_MODULE;
    if (this.selectedApp == Constants.STOCK_ADJUSTMENT) {
      moduleName = Constants.STOCK_ADJUSTMENT_MODULE;
    }
    this.dashboardService
      .export(moduleName)
      .subscribe(response => {
        this._snackBar.open(Constants.STOCK_EXPORT_SUCCESS_MSG, "", {
          duration: 3000,
          panelClass: ["toaster-success"],
          verticalPosition: "bottom",
          horizontalPosition: "center"
        });
      });
  }

  checkUserPermission(action:string) {
    switch(action){
      case 'stock_transfer_export':
        return Utility.checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.EXPORT,this.permissionsData,this.userRoles);
        break;
      case 'stock_transfer_create':
        return Utility.checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.CREATE,this.permissionsData,this.userRoles);
      case 'stock_adj_export':
        return Utility.checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_ADJUSTMENT.EXPORT,this.permissionsData,this.userRoles);
      case 'stock_adj_create':
        return Utility.checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_ADJUSTMENT.CREATE,this.permissionsData,this.userRoles);
    }
  }
}
