<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>Stock Management</span></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">


    <nb-action *ngIf="selectedApp=='stock-transfer' && checkUserPermission('stock_transfer_export') " class="control-item">
      <button _ngcontent-dvn-c346="" nbbutton="" matripple="" outline="" class="d-flex align-items-center p-2 mat-ripple appearance-outline size-medium status-primary shape-rectangle nb-transition" (click)="exportData()">
        <mat-icon class="mr-1 mt-1 export-icon">input</mat-icon>
        <span>{{ 'COMMON.EXPORT_BTN' | translate }}</span> </button>
    </nb-action>

    <nb-action *ngIf="selectedApp=='stock-adjustment'  && checkUserPermission('stock_adj_export')" class="control-item">
      <button _ngcontent-dvn-c346="" nbbutton="" matripple="" outline="" class="d-flex align-items-center p-2 mat-ripple appearance-outline size-medium status-primary shape-rectangle nb-transition" (click)="exportData()">
        <mat-icon class="mr-1 mt-1 export-icon">input</mat-icon>
        <span>{{ 'COMMON.EXPORT_BTN' | translate }}</span> </button>
    </nb-action>

    <nb-action *ngIf="selectedApp=='stock-transfer' && checkUserPermission('stock_transfer_create')" class="control-item">
      <button _ngcontent-dvn-c346="" nbbutton="" matripple="" class="mat-ripple appearance-filled size-medium status-primary shape-rectangle nb-transition create-button" (click)="onNewStockTransfer()">{{ 'COMMON.NEW_STOCK_TRANSFER' | translate }} </button>
    </nb-action>

    <nb-action *ngIf="selectedApp=='stock-adjustment'  && checkUserPermission('stock_adj_create')" class="control-item">
      <button _ngcontent-dvn-c346="" nbbutton="" matripple="" class="mat-ripple appearance-filled size-medium status-primary shape-rectangle nb-transition create-button" (click)="createStockAdjustment()">{{ 'COMMON.CREATE_BTN' | translate }} </button>
    </nb-action>
  </nb-actions>
</div>
