import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { API_URL } from '../../../common/constant';
import { Product } from '../../models/new-product.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockAdjustmentServiceService {
  userLoggedInSubject = new Subject<any>();
  userLoggedInObservable: Observable<any>;
  public productUOM: any[] = [];
  public totalElements: any;

  constructor(
      private http: HttpClient,
      private cookieService: CookieService,
      private router: Router,
  ) {
  }

  getStockAdjustment(params?: HttpParams) {
      return this.http.get(API_URL.GET_STOCK_ADJUSTMENT,{
        params: params
      })
  }
  saveStockAdjustmentData(data:any){
      return this.http.post(API_URL.SAVE_STOCK_ADJUSTMENT,data);
  }
  getProduct(mode, searchKey=""){
    if(mode == 'New'){
      return this.http.get(API_URL.PRODUCT_LIST + "?search=" + searchKey);
  }
  else{
      return this.http.get(API_URL.ALL_PRODUCT_LIST);
  }
  }
  getProductList(params?: HttpParams) {
    return this.http.get(`${API_URL.PRODUCT.BASE}?${params.toString()}`);
  }

  getProductBySequenceCode(data: any) {
    return this.http.post(`${API_URL.PRODUCT.BY_SEQ_CODE}`, data);
  }
  getWarehouse(params?: HttpParams){
    return this.http.get(API_URL.WAREHOUSE_LIST,{ params: params});
  }
  getWarehouseForStockAdjustment(){
    return this.http.get(API_URL.WAREHOUSE_LIST_FOR_DROPDOWN);
  }
  editStockAdjustment(data:any){
    return this.http.put(API_URL.SAVE_STOCK_ADJUSTMENT,data);
  }
  getValuation(params?: HttpParams){
    return this.http.get(API_URL.GET_VALUATION,{ params: params});
  }
  fetchAdvancedTrackingData(params?: HttpParams) {
    return this.http.get(
      `${API_URL.ADVANCED_TRACKING.FETCH_DATA}?${params.toString()}`
    );
  }
  updateTrackingInformation(product) {
    return this.http.put(`${API_URL.PRODUCT.BASE}/${product.id}`, product);
  }
  saveProduct(product: Product) {
    if (!isNaN(product.id)) {
      return this.http.put(`${API_URL.PRODUCT.BASE}/${product.id}`, product);
    } else {
      return this.http.post(API_URL.PRODUCT.BASE, product);
    }
  }
getTenant(id?: number) {
    return this.http.get(API_URL.TENANT.GET(id));
  }
  fetchUomList(params) {
    return this.http.get(API_URL.PRODUCT.UOM, {
      params: params
    });
  }

  getProducts(params?: HttpParams) {
    return this.http.get(`${API_URL.PRODUCT.BASE}?${params.toString()}`);
  }
  getBatchSerailByWarehouse(productCode) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set("productCode", productCode);
    queryParams = queryParams.set("isCombined", "true");
    return this.http.get(
      `${API_URL.ADVANCED_TRACKING.WAREHOUSE_PRODUCTS_ADVANCE_TRACK}`,
      {
        params: queryParams,
      }
    );
  }

  getPricesFromPriceList(payload: any) {
    return this.http.post(API_URL.PRICE_LIST, payload);
  }

  deleteAdjustment(adjustmentCode) {
      return this.http.delete(`${API_URL.DELETE_STOCK_ADJUSTMENT}/${adjustmentCode}`);
  }

   getStockAdjustmentReason(type: string) {
    return this.http
      .get(API_URL.ADJUSTMENT_REASON(type));
  }

  getWarehouseByCode(warehouseCode: string) {
    let finalURL = `${environment.appsUrl}/v1/products/inventories/warehouses/search?&allRRBDetails=true&query=code=${warehouseCode}`
    return this.http.get(finalURL);
  }

}
